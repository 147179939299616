import * as React from 'react';
import {graphql, Link} from 'gatsby';
import './styles.scss';
import {BasePageData, BasePageHeader} from '../helpers/base-page';
import styled, {createGlobalStyle} from 'styled-components';
import Background from '../images/404-background.svg';
import NotFoundPicture from '../images/404.svg';
import {Button} from '../components/shared/button';
import {MarkdownRemark} from '../models/markdown-remark';
import ReactMarkdown from 'react-markdown';

interface NotFoundPageData extends BasePageData {
  pageData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    subText: string;
    buttonText: string;
  }>;
}

const Title = styled.h1`
  margin: 0 0 12px;
  font-size: 48px;
  font-weight: 500;
  
  @media screen and (max-width: 920px) {
    font-size: 32px;
    line-height: 1.5em;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  margin: 0 auto 32px;
  max-width: 500px;

  @media screen and (max-width: 920px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const NotFoundPage = ({data}: {data: NotFoundPageData}): JSX.Element => {

  const GlobalStyle = createGlobalStyle`
    body {
      background: url(${Background}) top center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  `;

  const year = (new Date()).getFullYear();

  return (
    <React.Fragment>
      <GlobalStyle/>
      <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <BasePageHeader
          siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
          siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
          siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
          siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
          siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
          logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
          logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
          logoShort={data.logos.edges[0].node.frontmatter.logoShort}
          links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
          hubspotFormId={data.hubspot?.edges[0].node.frontmatter.hubSpotFormId}
          hubspotPortalId={data.hubspot?.edges[0].node.frontmatter.hubSpotPortalId}
          contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
          modules={data.modules.edges.map(m => m.node.frontmatter)}
          usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        />
        <main style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 111}}>
          <div style={{textAlign: 'center', color: 'white', padding: '0 24px'}}>
            <img src={NotFoundPicture} alt="" style={{maxWidth: 570, width: '100%'}}/>
            <Title>
              <ReactMarkdown>{data.pageData.edges[0].node.frontmatter.title}</ReactMarkdown>
            </Title>
            <Text>
              <ReactMarkdown>{data.pageData.edges[0].node.frontmatter.subText}</ReactMarkdown>
            </Text>
            <Link to="/">
              <Button color={'green'} buttonStyle={'default'}>
                <ReactMarkdown>{data.pageData.edges[0].node.frontmatter.buttonText}</ReactMarkdown>
              </Button>
            </Link>
          </div>
        </main>
        <footer style={{textAlign: 'center', padding: 38, color: '#cceeff'}}>
          <div style={{marginBottom: 24}}>
            {
              data.navigation.edges[0].node.frontmatter.socialLinks.map((item, index) => (
                <a href={item.url} key={index}>
                  <img style={{height: 20, marginLeft: 20, filter: 'brightness(10)', opacity: 0.8}} src={item.navItemName} alt={item.altText}/>
                </a>
              ))
            }
          </div>
          <div>
            © {year} LimeFlight
            <br className={'mobile-only'}/>
            <span className={'desktop-only'}> | </span>
            {
              data.navigation.edges[0].node.frontmatter.copyRightItems.map((item, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <span> | </span>}
                  <a href={item.url}>{item.itemName}</a>
                </React.Fragment>
              ))
            }
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  {
    ...BaseData
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/notFound.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            subText
            buttonText
          }
        }
      }
    }
  }
`;
